import React, { useState } from "react";
import DropIn from "braintree-web-drop-in-react";

export const PaymentPage = ({
    token,
    amount,
    reference,
    firstName,
    lastName,
    company,
    address1,
    address2,
    town,
    county,
    countryCode,
    postalCode,
    email,
    isFormValid
}) => {
    const [instance, setInstance] = useState();
    const [result, setResult] = useState("");
    const [paid, setPaid] = useState(false);
    const [isPaying, setIsPaying] = useState(false);

    const threeDSecureParameters = {
        amount: amount,
        email: email,
        billingAddress: {
          givenName: firstName,
          surname: lastName,
          streetAddress: address1,
          extendedAddress: address2,
          locality: town,
          region: county,
          postalCode: postalCode,
          countryCodeAlpha2: countryCode,
        },
        additionalInformation: {
          shippingGivenName: firstName,
          shippingSurname: lastName,
          shippingAddress: {
            streetAddress: address1,
            extendedAddress: address2,
            locality: town,
            region: county,
            postalCode: postalCode,
            countryCodeAlpha2: countryCode,
          },
        },
      };

    const buy = async () => {
        if (!isFormValid()) {
            window.scrollTo(0, 0);
            return;
        }
        try {
            setIsPaying(true);
            setResult("");
            const { nonce } = await instance.requestPaymentMethod({
                threeDSecure: threeDSecureParameters
            });
            const deviceData = instance.deviceData;
            const payload = {
                nonce,
                deviceData,
                amount,
                reference,
                firstName,
                lastName,
                company,
                address1,
                address2,
                town,
                county,
                countryCode,
                postalCode,
                email
            }
            const response = await postData('/create', payload);
            if (response.status === 200) {
                setPaid(true);
                setResult("Your payment was successful. Thank you.");
            }
            else {
                const responseText = await response.text();
                setResult(responseText);
                instance.clearSelectedPaymentMethod();
            }
        }
        catch (err) {
            if (err.message === "No payment method is available.") {
                setResult("Please enter a valid payment method.")
            } else {
                setResult(err.message);
            }
        }
        finally {
            setIsPaying(false);
        }
    }

    async function postData(url, data) {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        return response;
    }

    if (!token) {
        return (
            <div className="row justify-content-center mb-3">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                {!paid && <>
                    <div className="row justify-content-center mb-3">
                        <div className="col-md-12">
                            <DropIn
                                options={{ authorization: token, paypal: { flow: "vault" }, threeDSecure: true }}
                                onInstance={(instance) => {
                                    instance = setInstance(instance);
                                }}
                            />
                        </div>
                    </div>
                    {!isPaying && <div className="row justify-content-center mb-3">
                        <div className="col-md-6">
                            <button id="pay" className="btn btn-primary btn-lg btn-block" type="submit" onClick={buy}>Pay Now</button>
                        </div>
                    </div>}
                    {isPaying && <div className="row justify-content-center mb-3">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Submitting Payment...</span>
                        </div>
                    </div>}
                </>
                }
                {result.length > 0 && <div className="row justify-content-center mb-3">
                    <div className="col-md-12">
                        <div className={`alert ${paid ? "alert-primary" : "alert-danger"}`} role="alert">
                            {result}
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}